import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h5>{`How the app works`}</h5>
    <ol>
      <li parentName="ol">{`The user selects a `}<strong parentName="li">{`chapter`}</strong>{` they are reading.`}</li>
      <li parentName="ol">{`To use the `}<strong parentName="li">{`augmented reality feature`}</strong>{` they have to allow access to the smartphone's camera.`}</li>
      <li parentName="ol">{`Point the smartphone's `}<strong parentName="li">{`camera`}</strong>{` to the images in the magazine.`}</li>
      <li parentName="ol">{`The app detects an `}<strong parentName="li">{`image`}</strong>{` via a `}<strong parentName="li">{`marker`}</strong>{` and shows the related `}<strong parentName="li">{`video`}</strong>{`.`}</li>
    </ol>
    <p>{`As a help for the user, images with related videos are marked with a little `}<strong parentName="p">{`play icon`}</strong>{` in the
bottom right.`}</p>
    <p>{`The next pages will show an example of the magazine with a `}<strong parentName="p">{`detectable image`}</strong>{` and a `}<strong parentName="p">{`video`}</strong>{` of how
the app works.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      